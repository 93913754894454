.navbar{
    display: flex;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: calc(6px + 2vmin);
    font-weight:500;
    opacity: 100%;
    position: sticky;
    top:0;
    width:100vw;
    /* background: linear-gradient(180deg, rgb(184, 119, 253), 20%, rgba(159, 140, 180, 0.5), 70%, rgba( 255, 255, 255, 0)); */
    z-index: 99;
    
}
.navLogo{
    width: calc(61px + 2vmin);
    height: clac(40px +2vmin);
    margin-top: 15px;
    margin-left: 30px
}
.navLogo:hover{
    width: calc(61px + 3vmin);
    height: clac(40px + 3vmin);
}
.navBtn{
    margin: calc(10px + 2vmin);
    text-align: center;
    font-size: calc(6px + 2vmin);
    text-decoration: none;
    font-weight:500;
    color: var(--black);
}

.current{
    text-decoration: none;
    font-weight:800;
    color: var(--white);
}
.pages{
    text-decoration: none;
    font-weight:500;
    color: var(--black);
}
.pages:hover{
    font-weight:800;
}
.navMenu{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: calc(6px + 2vmin);
}
.navList{
    list-style: none;
    color: var(--white);
    background-color: #1E1233;
    opacity: 90%;
    text-align: left;
    position:absolute;
    top:40px;
    padding-bottom: 10px;
    border-radius: 18px;
}
.currentList{
    text-align: left;
    font-size: calc(6px + 2vmin);
    text-decoration: none;
    font-weight:800;
    color: var(--white);
    padding:15px;
}
.list{
    text-align: left;
    font-size: calc(6px + 2vmin);
    text-decoration: none;
    font-weight:500;
    color: var(--white);
    padding: 15px;
}
ul{
  margin-top:3vh;
}
li{
    margin-top: 10px;
}