.terms, .privacy{
    width: 75vw;
}
.priv{
    text-align: center;
}

.privacyDate{
    /* font-size: calc(16px + 1vmin); */
    font-weight: 700;
    font-style: italic;
    font-family: 'Merriweather', serif;
    text-align: center;
    margin-top: 7px;
}
.privacyContact{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.privacyLabel{
    font-size: calc(10px + 1.25vmin);
    font-weight: 700;
    font-family: 'Merriweather', serif;
    text-align: left;
    margin-top:0px;
    margin-bottom: 0px;
}
.privacyHeading{
    font-size: calc(20px + 1vmin);
    font-weight: 700;
    font-style: italic;
    font-family: 'Merriweather', serif;
    text-align: left;
    margin-bottom: 8px;
}
.privacyText{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }