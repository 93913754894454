.meetLiz{
    margin: 10px;
    align-self: flex-start;
    margin-left: 10vw;
}
.pictureText{
    display: flex;
    flex-direction: row;
}
.lizImg{
    height: 60vh;
    width: calc(60vh * 0.7);
    max-width: 320px;
    max-height: 456.5px;
    margin-top: 3.5vh;
}
.homeText{
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-width: 560px;
    margin-left: 5vw;
}
.homeBanner{
    width: 100vw;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
}
.homeBanner p{
    font-size: calc(1.5vmin);
    font-style: italic;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--purple);
    width:75vw;
    text-align: center;
}

.quoteText{
  font-size: calc(2vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  font-weight: 600;
  font-style:italic;
  margin-top: 10px;
  margin-bottom: 10px;
  width:65vw;
  color: var(--white);
}
.quoteReference{
  font-size: calc(1.5vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width:60vw;
  font-weight:400;
  color: var(--white);
  white-space:nowrap;
}
#arrow{
  display:inline-block;
  transform: rotate(180deg);
}
#arrow2{
  display:inline-block;
  transform: rotate(180deg);
}
.hidden{
    opacity:0
}
.visible{
    opacity: 1
}
#p5:hover, #p7:hover{
    cursor: pointer;
}
@media screen and (max-width: 1024px){
    .pictureText{
      flex-direction: column;
    }
    .lizImg{
        margin-top: 0px;
        align-self: center;
    }
    .homeText{
        width: 90vw;
        margin-top: 2vh;
    }
    #p1, #p2, #p3, #p4{
      text-align:center;
    }
    .meetLiz{
        margin: 0px;
    }
  }