
.contactSection{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.contactLabel{
    font-size: calc(16px + 1.25vmin);
    font-weight: 700;
    font-style: italic;
    font-family: 'Merriweather', serif;
    text-align: left;
    margin-top: 7px;
}
.contactInput{
    font-size: 1.4vmin;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
    margin-top: 4px;
    padding: 10px;
    width: 40vw;
    border: solid 0.5px var(--purple)
}
.contactMessage{
    height: 35vh;
}
.formAndMap{
    display: flex;
    flex-direction: row;
}
.mapAndContact{
    display: flex;
    flex-direction: column;
    align-items: center;    
}
iframe{
    margin: 10px;
}
.contactText{
  width: 40vw;
}

@media only screen and (max-width: 900px) {
  .formAndMap {
    flex-direction: column;
  }
  .contactInput .contactList{
    width: 100vw
  }
}