@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
:root{
  --lightPurple: rgb(245 208 254);
  --purple: rgb(153, 7, 197);
  --darkPurple: rgb(74 4 78);
  --black: #1c1c1c;
  --white: #eaeaea;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-header {
  background-size:cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 40vh;
  opacity:90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:65px;
  filter: brightness();
} 
.App-body{
  margin-top: 34vh;
}
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65vw;
  margin-top: 5vh;
}
.title{
  font-size: calc(55px + 2vmin);
  font-weight: 700;
  font-style: italic;
  font-family: 'Merriweather', serif;
  text-align: center;
  padding-top: 5vh;
  color: var(--purple);
  margin-top: 5vh;
}
.subTitle{
  font-size: calc(45px + 2vmin);
  font-weight: 700;
  font-style: italic;
  font-family: 'Merriweather', serif;
  text-align: center;
  color: var(--purple);
  margin-bottom: 2vh;
}
.heading {
  font-size: calc(25px + 2vmin);
  font-weight: 700;
  font-style: italic;
  font-family: 'Merriweather', serif;
  text-align: left;
  margin-top: 9px;
}
.subHeading{
  font-size: calc(16px + 2vmin);
  font-weight: 700;
  font-style: italic;
  font-family: 'Merriweather', serif;
  text-align: left;
  margin-top: 7px;
  color: var(--darkPurple);
}
.merri{
  font-family: 'Merriweather', serif;
}
 
.normalText{
  font-size: calc(10px + 1vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* text-align: left; */
  margin-top: 4px;
  color: var(--black);
}
.whiteText{
  font-size: calc(12px + 2vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  margin-top: 4px;
  color: var(--white);
}
.linkText{
  font-size: calc(8px + 1vmin);
  font-family: 'Source Code Pro', monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: var(--black);
  margin-left: -60vw;
  padding-bottom: 5vh;
}
.linkText:hover{
  text-decoration: none;
  color: var(--darkPurple);
  font-size: calc(10px + 1vmin);
}
.smallText{
  font-size: calc(5px + 1vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 500;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  color: var(--black);
  font-style: italic;
}
.feesBtn{
  width: fit-content;
  height: fit-content;
  background-color: var(--purple);
  color: var(--white);
  font-family: 'Merriweather', serif;
  font-style:italic;
  font-size: calc(16px + 1vmin);
  font-weight: bold;
  border: none;
  border-radius: 12px;
  margin: 20px;
  padding: 6px 18px;
}
.feesBtn:hover{
  background-color: var(--darkPurple);
}
.bottomBanner{
  margin-top: 3vh;
  width: 100vw;
  background-color: var(--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email{
  font-size: calc(10px + 1vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  margin-top: 4px;
  color: var(--black);
  text-decoration: underline;
  margin-top:2vh;
}
.email:hover{
  color: var(--darkPurple);
  font-weight: 700;
  text-decoration: none;
}
.priceList{
  list-style-position: inside;
  margin-top: -20px;
}
.priceList li{
  font-size: calc(10px + 1vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin-top: 4px;
  color: var(--black);
}
.banner{
  width: 75vw;
  background: linear-gradient( 90deg, white, var(--white), white);
  /* background-color: var(--white); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: -5vh;
}
.banner h2{
  font-size: calc(12px + 1vmin);
  font-style: italic;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--purple);
  /* width:25vw; */
  text-align: center;
}
.counsellingModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 5vw;
  width: 60vw;
  transform: translateX(35%) translateY(45%);
  text-align: center;
}
.mTitle {
  font-size: calc(25px + 2vmin);
  font-weight: 700;
  font-style: italic;
  font-family: 'Merriweather', serif;
  margin-top: 5px;
  color: var(--darkPurple);
}
.mBody{
  text-align: center;
}