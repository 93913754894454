.footerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer{
    display: flex;
    flex-direction: row;
    align-self: center;
}
.footerLogo{
    width: 10vw;
    height: auto;
    max-width: 205px;
    max-height: 85px;
    margin: 20px;
}
.footerText{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
}
.termsLink{
    text-decoration: underline;
    color: var(--black);
    margin-top: 15px;
}
.termsLink:hover{
    font-weight: 700;
    color: var(--darkPurple);
    text-decoration: none;
}